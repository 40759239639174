<template>
    <div>
        <CCollapse :show="activeItem(1)">
            <Reception/>
        </CCollapse>
        <CCollapse :show="activeItem(2)">
            <Dispatch/>
        </CCollapse>
        <CCollapse :show="activeItem(3)">
            <ContainerPositioning/>
        </CCollapse>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import Reception from './reception/reception-index';
    import Dispatch from './dispatch/dispatch-index';
    import ContainerPositioning from './container-positioning/container-positioning-index';

    //methods
    function activeItem(item) {
        return this.dropContainerMovement === item;
    }
    export default {
        name:"movements-index",
        methods:{
            activeItem,
        },
        components:{
            Reception,
            Dispatch,
            ContainerPositioning,
        },
        computed:{
            ...mapState({
                tabIndex: state => state.yard-management.tabMovementIndex,
                dropContainerMovement: state => state.yardManagement.dropContainerMovement,
            })
        },
        watch:{
            //tabIndex
        }
    }
</script>