import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import {saveAs} from "file-saver";
import Excel from "exceljs/dist/es5/exceljs.browser";
import { mapState } from 'vuex';

function data() {
    return {
      dataBuque:{
        CountryName:'',
        DatePlanning:'',
        VesselFlagRoute:'',
      },
    }
  }

async function getPdf(rowData, titulo, colDelete, tableHeader) {
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");
    let columns = [];
    //if (isUndefined(un)) un = 'TON';
    if (tableHeader) {
        for (let index = 0; index < tableHeader.length; index++) {
            columns.push({
                title: tableHeader[index].title,
                dataKey: tableHeader[index].dataKey
            });
        }
    }else{
        let columnsDefs = [];
        if (this.gridApi!==undefined) {
            columnsDefs = this.gridApi.getColumnDefs();
        }

        if(columnsDefs.length > 0){
            if(colDelete)
            columnsDefs.splice(0, colDelete);
        }

        for (let index = 0; index < columnsDefs.length; index++) {
            //if (this.columnDefs[index].headerName.toUpperCase() == "WEIGTH") this.columnDefs[index].headerName = this.columnDefs[index].headerName + " (" + un + ")";
            columns.push({
                title: columnsDefs[index].headerName,
                dataKey: columnsDefs[index].field
            });
        }
    }

    const response1 = await this.$http.getFile('base64', {
        path:this.user.LogoRoute.replace('Public/','', null, 'i')
    });
    const divisiones1 = this.user.LogoRoute.split(".");
    const myBase64Image = 'data:image/'+divisiones1[1]+';base64,'+response1.data.data.base64;

    let largo = columns.length*70;
    let resta = 0;
    let tcentro = 550;
    let font = 8;
    let widthImage = 59;
    let positionX = 115;
    let sizeText = 9;
    let tableWidthBody = 'auto';

    if (columns.length > 9){
        widthImage = 120;
        positionX = 165;
        sizeText = 10;
        tableWidthBody = 970;
    }

    if (columns.length > 14) font=7;

    if (largo < 700) {
        largo = 700;
        resta = 350;
        tcentro = 350;
    //} else if ((largo > 600) && (largo < 850)) {
    //    largo = 850;
    } else {
        largo = 1050;
    }
    const doc = new jsPDF({
        orientation: 'l',
        unit: 'pt',
        format: [600, largo],
        putOnlyUsedFonts:true,
        floatPrecision: 16 // or "smart", default is 16
    });

    const addFooters = doc => {
        const pageCount = doc.internal.getNumberOfPages();
        const hoy = new Date();
        //let fecha = hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
        let fecha  = DateFormater.formatTimeZoneToDateTime(hoy);
        doc.setFontSize(8);
        doc.setTextColor("#000");
        for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(this.$t('label.printed')+": "+ fecha, 40, 570, {
                align: 'left'
            });
            if(this.loadText !== undefined && this.loadText !== null){
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName + " / LOAD LIST FILTER BY: "+this.loadText, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }else{
                doc.text(this.$t('label.user')+": " + this.user.UserName + " " + this.user.UserLastName, doc.internal.pageSize.width - 50, 570, {
                    align: 'right'
                });
            }
            doc.text(this.$t('label.pageReport')+ ' ' + String(i)+ ' ' + this.$t('label.ofReport')+ ' ' + String(pageCount), doc.internal.pageSize.width - 50, 585, {
                align: 'right'
            })
        }
    }

    doc.setDrawColor("#fff");
    //doc.rect(40, 40, 65, 70);
    doc.addImage(myBase64Image, "JPEG", 43, 43, widthImage, 63);

    doc.setDrawColor("#fff");
    doc.rect(positionX, 40, 905 - resta, 40);
    doc.setFontSize(sizeText);
    doc.text(this.user.CompanyName + ' ' + this.user.CompanyRif + ' - ' +this.user.BranchJson[0].BranchName, tcentro, 65, 'center');

    doc.setFillColor("#cccccc");
    doc.rect(40, 105, 970-resta, 3, 'F');

    doc.setFillColor("#cccccc");
    doc.rect(40, 110, 970-resta, 25, "FD");
    doc.setFontSize(sizeText);
    doc.text(titulo, tcentro, 125, 'center');

    /*if (data.Cont20FtDeck == "DECK") {

    }*/
    //let arreglo = rowData.shift();
    let inicio = 145;
    if (rowData[0].Cont20FtDeck == "DECK") {
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");
        doc.setFillColor("#1a3760");
        doc.rect(150, 145, 320, 20, "FD");
        doc.text(rowData[0].Cont20FtDeck, 280, 160, 'center');
        doc.setFillColor("#ec631b");
        doc.rect(437, 145, 285, 20, "FD");
        doc.text(rowData[0].Cont20FtHold, 580, 160, 'center');
        doc.setFillColor("#5d6164");
        doc.rect(722, 145, 288, 20, "FD");
        doc.text(rowData[0].Cont20FtTotal, 880, 160, 'center');
        rowData.shift();
        inicio = 165;
    } else if (rowData[0].Cont20Full == "20F") {
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");
        doc.setFillColor("#1a3760");
        doc.rect(232, 145, 140, 20, "FD");
        doc.text(rowData[0].Cont20Full, 295, 160, 'center');
        doc.setFillColor("#1a3760");
        doc.rect(363, 145, 140, 20, "FD");
        doc.text(rowData[0].Cont20Empty, 425, 160, 'center');

        doc.setFillColor("#ec631b");
        doc.rect(495, 145, 140, 20, "FD");
        doc.text(rowData[0].Cont40Full, 550, 160, 'center');
        doc.setFillColor("#ec631b");
        doc.rect(620, 145, 142, 20, "FD");
        doc.text(rowData[0].Cont40Empty, 680, 160, 'center');

        doc.setFillColor("#5d6164");
        doc.rect(750, 145, 140, 20, "FD");
        doc.text(rowData[0].Cont45Full, 810, 160, 'center');
        doc.setFillColor("#5d6164");
        doc.rect(880, 145, 130, 20, "FD");
        doc.text(rowData[0].Cont45Empty, 938, 160, 'center');
        rowData.shift();
        inicio = 165;
    } else if (rowData[0].CtaFunctionCode == "CONTACT INFORMATION") {
        doc.setFontSize(7);
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");

        doc.setFillColor("#1a3760");
        doc.rect(190, 145, 137, 20, "FD");
        doc.text(rowData[0].CtaFunctionCode, 260, 160, 'center');

        doc.setFillColor("#1a3760");
        doc.rect(327, 145, 90, 20, "FD");
        doc.text("CONTACT", 370, 160, 'center');

        doc.setFillColor("#ec631b");
        doc.rect(417, 145, 275, 20, "FD");
        doc.text(rowData[0].HanDescriptionCode, 530, 160, 'center');

        doc.setFillColor("#5d6164");
        doc.rect(692, 145, 190, 20, "FD");
        doc.text(rowData[0].TsrServicePriorityCode, 780, 160, 'center');
        rowData.shift();
        inicio = 165;
    } else if (rowData[0].section == "totalLoad") {
        doc.setFontSize(7);
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");

        doc.setFillColor("#1a3760");
        doc.rect(151, 145, 255, 20, "FD");
        doc.text("DECK", 280, 160, 'center');

        doc.setFillColor("#ec631b");
        doc.rect(403, 145, 257, 20, "FD");
        doc.text("HOLD", 520, 160, 'center');
        rowData.shift();
        inicio = 165;
    } else if (rowData[0].total == "DELAY_LIST") {
        doc.setFontSize(7);
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");

        doc.setFillColor("#1a3760");
        doc.rect(860, 145, 38, 20, "FD");
        doc.text("FULL", 875, 160, 'center');

        doc.setFillColor("#ec631b");
        doc.rect(897, 145, 38, 20, "FD");
        doc.text("EMPTY", 915, 160, 'center');
        rowData.shift();
        inicio = 165;
    } else if (rowData[0].section == "transport_summary") {
        let _lang = this.$i18n.locale;
        doc.setDrawColor("#EEE");
        doc.setTextColor("#fff");

        let posX =_lang=='en' ? 305 : 274;
        let ancho =_lang=='en' ? 325 : 344;

        doc.setFillColor("#1a3760");
        doc.rect(posX, 145, ancho, 20, "FD");
        doc.text(this.$t('label.orders'), posX+173, 160, 'center');

        doc.setFillColor("#ec631b");
        posX =_lang=='en' ? 620 : 618;
        ancho =_lang=='en' ? 210 : 228;
        doc.rect(posX, 145, ancho, 20, "FD");
        doc.text(this.$t('label.times'), posX+105, 160, 'center');

        doc.setFillColor("#5d6164");
        posX =_lang=='en' ? 827 : 845;
        ancho =_lang=='en' ? 183 : 165;
        doc.rect(posX, 145, ancho, 20, "FD");
        doc.text(this.$t('label.ton')+'S', posX+75, 160, 'center');

        rowData.shift();
        inicio = 165;
    }

    doc.autoTable(columns, rowData, {
        startY: inicio,
        theme: 'grid',
        columnWidth: 70,
        showHeader: 'everyPage',
        tableLineColor: 200,
        tableLineWidth: 0,
        margin: { bottom: 45 },
        tableWidth: tableWidthBody,
        styles: {
            cellPadding: 1,
            fontSize: font,
            halign: "center",
            valign: "middle"
        },
        headStyles: {
            fillColor: "#EEE",
            textColor: "#000",
            halign: "center",
        },
        columnStyles: {
            overflow: 'linebreak',
        },
        /*styles: {
            overflow: 'linebreak',
            columnWidth: 'wrap',
           // font: 'arial',
            fontSize: font,
            cellPadding: 2,
            halign: "center",
            overflowColumns: 'linebreak'
        },*/
    });

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();

    addFooters(doc);
    doc.save(`${fileName}${today}${time}.pdf`);//
    this.$notify({
        group: 'container',
        text: this.$t('label.reportSuccessfully'),
        type: "success"
    });
}

async function getReporExcel(rowData, titulo, excel, val, YardName, colDelete, tableHeader, header = '') {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    if (tableHeader) {
        columnsDefs = tableHeader.label;
        keyColumns = tableHeader.key;
        y = columnsDefs.length;
        bandera=false;
    }else if (this.gridApi!==undefined) {
        columnsDefs = this.gridApi.getColumnDefs();
        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }

    if(colDelete)
        {
            keyColumns.splice(0, colDelete);
            columnsDefs.splice(0, colDelete);
            y = y-colDelete;
        }
    //if (isUndefined(un)) un = 'TON';
    if (excel) {

        if (y < 10) letra = 8;
            worksheet.mergeCells('A1:B2');
            const response1 = await this.$http.getFile('base64', {
                path: this.user.LogoRoute.replace('Public/', '', null, 'i')
            });

            const divisiones1 = this.user.LogoRoute.split(".");
            const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
            const imageId = workbook.addImage({
                base64: myBase64Image,
                extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            const rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;


                worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
                worksheet.mergeCells("C2:" + numeroLetra(y) + "2");

            const customCellCompanyBranch = worksheet.getCell("C1");

            const row1 = worksheet.getRow(1);
            row1.height = 30;
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }


                const row2 = worksheet.getRow(2);
                row2.height = 30;
                const customCell = worksheet.getCell("C2");
                customCell.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'f2f2f2'
                    }
                }
                customCell.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                customCell.value = header!=''? header : YardName!='' ? YardName : this.yardData?.YardName;
                customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }


        //titulo
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
        const customCell2 = worksheet.getCell("A3");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = titulo;
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //division de la fila
        let division = Math.floor(y / val.length);
        let Rango = [];
        let Extra = (y-(division*val.length));
        for (let index = 0 , Num = 1; index < val.length; index++, Num++) {
            if (index < (val.length-1)) {
                if (index==0) {
                    if (Extra>=division) {
                        Num++;
                        Rango.push({
                            rango1: numeroLetra(division*Num),
                            rango11: numeroLetra((division*Num) +1),
                        })
                    }else{
                        Rango.push({
                            rango1: numeroLetra(division),
                            rango11: numeroLetra((division) +1),
                        })
                    }
                }else{
                    Rango.push({
                        rango1: numeroLetra(division*Num),
                        rango11: numeroLetra((division*Num) +1),
                    })
                }
            }else{
                Rango.push({
                    rango1: numeroLetra(y),
                })
            }
        }

        //rango de la filas
        worksheet.getRow(4).height = 35
        worksheet.mergeCells("A4:" + Rango[0].rango1 +"4");
        const customCell3 = worksheet.getCell("A4");
        customCell3.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };

        customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell3.value = val[0];
        customCell3.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        for (let index = 0; index < (Rango.length-1); index++) {
            worksheet.mergeCells(Rango[index].rango11 + "4:" + Rango[index+1].rango1 + "4");
            let Cell = worksheet.getCell(Rango[index].rango11+"4")
            Cell.height = 50;
            Cell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            Cell.value = val[index+1];
            Cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            Cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }

        //linea
        let linea = (Rango.length - 1);
        worksheet.mergeCells("A5:"+Rango[linea].rango1+"5");
        const customCell8 = worksheet.getCell("A5");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    }

    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    /*headerRow.border = {
        top: {style:'thin'},
        bottom: {style:'thin'},
    }
    */
    headerRow.height = 30;

    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=2) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }

        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data;
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}
        i++;
    })

    rowData.map(async function(data){
        let dataRow = worksheet.addRow();
        let indice = 0;

        keyColumns.map(function (data2) {
            let cell = dataRow.getCell(indice + 1);
            cell.value = data[data2];
            if (indice!=0 && !isNaN(data[data2])) {
                cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
            }
            //if (cell.value==)
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
        })
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}


async function getReporExcelCanceled(rowData, titulo, excel, val, YardName, colDelete, tableHeader, header = '') {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(titulo);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let keyColumns = [];
    let bandera = true;
    if (tableHeader) {
        columnsDefs = tableHeader.label;
        keyColumns = tableHeader.key;
        y = columnsDefs.length;
        bandera=false;
    }else if (this.gridApi!==undefined) {
        let titles = this.gridApi.getColumnDefs();
        
        titles.map((item)=>{
            if(item.children){
                let  children =  item.children
                children.map((j)=>{
                    j.children.map((k)=>{
                        columnsDefs.push(k)
                    })
                })
            }else
                columnsDefs.push(item)
        })

        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }

    if(colDelete)
        {
            keyColumns.splice(0, colDelete);
            columnsDefs.splice(0, colDelete);
            y = y-colDelete;
        }
    //if (isUndefined(un)) un = 'TON';
    if (excel) {

        if (y < 10) letra = 8;
            worksheet.mergeCells('A1:B2');
            const response1 = await this.$http.getFile('base64', {
                path: this.user.LogoRoute.replace('Public/', '', null, 'i')
            });

            const divisiones1 = this.user.LogoRoute.split(".");
            const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
            const imageId = workbook.addImage({
                base64: myBase64Image,
                extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            const rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;


                worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
                worksheet.mergeCells("C2:" + numeroLetra(y) + "2");

            const customCellCompanyBranch = worksheet.getCell("C1");

            const row1 = worksheet.getRow(1);
            row1.height = 30;
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }


                const row2 = worksheet.getRow(2);
                row2.height = 30;
                const customCell = worksheet.getCell("C2");
                customCell.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'f2f2f2'
                    }
                }
                customCell.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                customCell.value = header!=''? header : YardName!='' ? YardName : this.yardData?.YardName;
                customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }


        //titulo
        worksheet.getRow(3).height = 30;
        worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
        const customCell2 = worksheet.getCell("A3");
        customCell2.font = {
            name: "Calibri",
            family: 4,
            size: 12,
            underline: false,
            bold: true
        };
        customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell2.value = titulo;
        customCell2.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //division de la fila
        let division = Math.floor(y / val.length);
        let Rango = [];
        let Extra = (y-(division*val.length));
        for (let index = 0 , Num = 1; index < val.length; index++, Num++) {
            if (index < (val.length-1)) {
                if (index==0) {
                    if (Extra>=division) {
                        Num++;
                        Rango.push({
                            rango1: numeroLetra(division*Num),
                            rango11: numeroLetra((division*Num) +1),
                        })
                    }else{
                        Rango.push({
                            rango1: numeroLetra(division),
                            rango11: numeroLetra((division) +1),
                        })
                    }
                }else{
                    Rango.push({
                        rango1: numeroLetra(division*Num),
                        rango11: numeroLetra((division*Num) +1),
                    })
                }
            }else{
                Rango.push({
                    rango1: numeroLetra(y),
                })
            }
        }

        //rango de la filas
        worksheet.getRow(4).height = 35
        worksheet.mergeCells("A4:" + Rango[0].rango1 +"4");
        const customCell3 = worksheet.getCell("A4");
        customCell3.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true
        };

        customCell3.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell3.value = val[0];
        customCell3.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        for (let index = 0; index < (Rango.length-1); index++) {
            worksheet.mergeCells(Rango[index].rango11 + "4:" + Rango[index+1].rango1 + "4");
            let Cell = worksheet.getCell(Rango[index].rango11+"4")
            Cell.height = 50;
            Cell.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            Cell.value = val[index+1];
            Cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            Cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }

        //linea
        let linea = (Rango.length - 1);
        worksheet.mergeCells("A5:"+Rango[linea].rango1+"5");
        const customCell8 = worksheet.getCell("A5");
        customCell8.height = 50;
        customCell8.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true,
        };
        customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell8.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }

        //recepción a buque
        worksheet.getRow(6).height = 30
        worksheet.mergeCells("J6:" + "S6");
        const customCell9 = worksheet.getCell("J6");
        customCell9.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true,
            color: {argb: 'ffffff'}
        };
        customCell9.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell9.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell9.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '42aa90'
            }
        }
        customCell9.value = `${this.$t('label.receptionToShip')}`

        //recepción en buque
        worksheet.getRow(7).height = 30
        worksheet.mergeCells("J7:" + "O7");
        const customCell10 = worksheet.getCell("J7");
        customCell10.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true,
            color: {argb: 'ffffff'}
        };
        customCell10.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell10.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell10.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '1f3864'
            }
        }
        customCell10.value = `${this.$t('label.reception')}`

        //despacho en buque
        worksheet.getRow(7).height = 30
        worksheet.mergeCells("P7:" + "S7");
        const customCell11 = worksheet.getCell("P7");
        customCell11.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true,
            color: {argb: 'ffffff'}
        };
        customCell11.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell11.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell11.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'ed7d31'
            }
        }
        customCell11.value = `${this.$t('label.dispatch')}`

        //retorno
        worksheet.getRow(7).height = 30
        worksheet.mergeCells("T7:" + "W7");
        const customCell12 = worksheet.getCell("T7");
        customCell12.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true,
            color: {argb: 'ffffff'}
        };
        customCell12.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell12.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell12.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: '1f3864'
            }
        }
        customCell12.value =`${this.$t('label.returned')}`
        
        //despacho
        worksheet.getRow(7).height = 30
        worksheet.mergeCells("X7:" + "AF7");
        const customCell13 = worksheet.getCell("X7");
        customCell13.font = {
            name: "Calibri",
            family: 4,
            size: letra,
            underline: false,
            bold: true,
            color: {argb: 'ffffff'}
        };
        customCell13.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        customCell13.border = {
            top: { style: 'thin' },
            bottom: { style: 'thin' },
            left: { style: 'thin' },
            right: { style: 'thin' },
        }
        customCell13.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'ed7d31'
            }
        }
        customCell13.value = `${this.$t('label.dispatch')}`
    }

    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    /*headerRow.border = {
        top: {style:'thin'},
        bottom: {style:'thin'},
    }
    */
    headerRow.height = 30;

    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=2) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }

        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data;
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}
        i++;
    })

    rowData.map(async function(data){
        let dataRow = worksheet.addRow();
        let indice = 0;

        keyColumns.map(function (data2) {
            let cell = dataRow.getCell(indice + 1);
            cell.value = data[data2];
            if (indice!=0 && !isNaN(data[data2])) {
                cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
            }
            //if (cell.value==)
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
        })
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}
async function getExcel(rowData, titulo, excel, title, companyName, membrete=true, nombreLibro,  ) {
    const workbook = new Excel.Workbook();
    let nombreOpcional = nombreLibro ? nombreLibro : titulo;
    const worksheet = workbook.addWorksheet(nombreOpcional);
    const documento = titulo.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))).replaceAll(" ", "");

    let y = 0;
    let letra = 12;
    let columnsDefs = [];
    let columnsDefs2 = [];
    let keyColumns = [];
    let bandera = true;

    if (this.gridApi!==undefined) {
        columnsDefs = this.gridApi.getColumnDefs();
        this.columnApi.getColumnState().map(function (e) {
            if (typeof e.colId === 'string') {
                keyColumns.push(e.colId);
            }
            y++;
        });

        if (columnsDefs[0].field =='messagesContent') {
            for (let i = 0; i < columnsDefs.length; i++) {
                if ((columnsDefs[i].field != 'messagesContent') && (columnsDefs[i].field != 'especial')) {
                    columnsDefs2.push(columnsDefs[i]);
                }
            }
            columnsDefs = columnsDefs2;
            keyColumns.splice(0, 2);
            y=y-3;
        }
    } else {
        columnsDefs = Object.keys(rowData[0]);
        keyColumns = columnsDefs;
        y = columnsDefs.length;
        bandera=false;
    }

    //if (isUndefined(un)) un = 'TON';
    if(membrete){
        if (excel) {

            if (y < 10) letra = 8;
            worksheet.mergeCells('A1:B2');
            const response1 = await this.$http.getFile('base64', {
                path: this.user.LogoRoute.replace('Public/', '', null, 'i')
            });

            const divisiones1 = this.user.LogoRoute.split(".");
            const myBase64Image = 'data:image/' + divisiones1[1] + ';base64,' + response1.data.data.base64;
            const imageId = workbook.addImage({
                base64: myBase64Image,
                extension: divisiones1[1],
            });
            worksheet.addImage(imageId, 'A1:B2');
            worksheet.getCell("A1").border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            const rowIndex1 = worksheet.getRow(1);
            rowIndex1.height = 100;

            if(companyName){
                worksheet.mergeCells("C1:" + numeroLetra(y) + "2")
            }else if(companyName === undefined){
                worksheet.mergeCells("C1:" + numeroLetra(y) + "1");
                worksheet.mergeCells("C2:" + numeroLetra(y) + "2");
            }

            const customCellCompanyBranch = worksheet.getCell("C1");

            const row1 = worksheet.getRow(1);
            row1.height = companyName ? 60 : 30;
            customCellCompanyBranch.font = {
                name: "Calibri",
                family: 4,
                size: letra,
                underline: false,
                bold: true
            };
            customCellCompanyBranch.note = {
                margins: {
                    insetmode: 'auto',
                    inset: [10, 10, 5, 5]
                }
            }
            customCellCompanyBranch.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'f2f2f2'
                }
            }
            customCellCompanyBranch.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            customCellCompanyBranch.value = this.user.CompanyName+' '+this.user.CompanyRif+' - '+this.user.BranchJson[0].BranchName;
            customCellCompanyBranch.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }

            if(companyName){
            }else if(companyName === undefined){
                const row2 = worksheet.getRow(2);
                row2.height = 30;
                const customCell = worksheet.getCell("C2");
                customCell.font = {
                    name: "Calibri",
                    family: 4,
                    size: letra,
                    underline: false,
                    bold: true
                };
                customCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'f2f2f2'
                    }
                }
                customCell.border = {
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                }
                let titleVessel = `${this.dataContainer?.VesselName}, ${this.$t('label.voyage')}: ${this.dataContainer?.VoyageArrival} ${(this.dataContainer?.Ata && this.dataContainer?.Ata != null) ? ', ATA :' : ''} ${(this.dataContainer?.Ata && this.dataContainer?.Ata != null) ? DateFormater.formatDateTimeWithSlash(this.dataContainer?.Ata) : ''} ${(this.dataContainer?.Atd && this.dataContainer?.Atd != null) ? '- ATD:' : ''} ${(this.dataContainer?.Atd && this.dataContainer?.Atd != null) ? DateFormater.formatDateTimeWithSlash(this.dataContainer?.Atd) : ''}`

                customCell.value = titleVessel;
                customCell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            }

            //titulo
            worksheet.getRow(3).height = 30;
            worksheet.mergeCells("A3:" + numeroLetra(y) +"3");
            const customCell2 = worksheet.getCell("A3");
            customCell2.font = {
                name: "Calibri",
                family: 4,
                size: 12,
                underline: false,
                bold: true
            };
            customCell2.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            customCell2.value = title;
            customCell2.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //COLUMNA 2
            let division = Math.floor(y / 3);
            let rango1 = numeroLetra(division);
            let rango11 = numeroLetra(division+1);
            let rango2 = numeroLetra(division*2);
            let rango21 = numeroLetra((division * 2) +1);
            let rango3 = numeroLetra(y);

            const hoy = new Date();

            if(companyName){
                worksheet.mergeCells("A4:" + rango1 +"4");
                const customCellCompany = worksheet.getCell("A4");
                worksheet.mergeCells(rango11 + "4:" + rango2 + "4");
                worksheet.mergeCells(rango21 + "4:" + rango3 + "4");

                customCellCompany.height = 50;
                customCellCompany.font = {
                    name: "Calibri",
                    family: 4,
                    size: 11,
                    underline: false,
                    bold: true
                };

                customCellCompany.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
                customCellCompany.value = this.$t('label.company')+": "+companyName;
                customCellCompany.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                    left: { style: 'thin' },
                }
            }else if(companyName === undefined){
                worksheet.mergeCells("A4:" + rango2 +"4"); //fecha de impresion
                worksheet.mergeCells(rango21 + "4:" + rango3 + "4");  //usuario
            }

            const customCell6 = companyName ? worksheet.getCell(rango11 + "4") : worksheet.getCell("A4");
            customCell6.height = 50;
            customCell6.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };

            customCell6.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCell6.value = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(hoy);//hoy.toLocaleDateString()+" - "+hoy.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
            customCell6.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

            //usuario
            const customCell7 = companyName ? worksheet.getCell(rango21 + "4") : worksheet.getCell(rango21 + "4");
            customCell7.height = 50;
            customCell7.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };

            customCell7.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' }
            customCell7.value = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
            customCell7.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            }

            //linea
            worksheet.mergeCells("A5:" + rango3 +"5");
            const customCell8 = worksheet.getCell("A5");
            customCell8.height = 50;
            customCell8.font = {
                name: "Calibri",
                family: 4,
                size: 11,
                underline: false,
                bold: true
            };
            customCell8.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        }
    }


    var headerRow = worksheet.addRow();
    headerRow.font =  {
        name: "Calibri",
        family: 4,
        size: 11,
        underline: false,
        bold: true
    };
    headerRow.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
    /*headerRow.border = {
        top: {style:'thin'},
        bottom: {style:'thin'},
    }
    */
   // headerRow.height = 30;

    let i = 0;
    columnsDefs.map(function(data){
        let valor = bandera ? data.headerName.length : data.length;
        let long = 10;
        if ((valor>=5) && (valor<=12)) {
            long = 20;
        } else if ((valor>=11) && (valor<=18)) {
            long = 25;
        } else if ((valor >= 19) && (valor <= 26)) {
            long = 30;
        } else if (valor>=27) {
            long = 40;
        }

        //if (data.headerName.toUpperCase() == "WEIGHT") data.headerName = data.headerName + " (" + un + ")";

        worksheet.getColumn(i + 1).width = long;
        //if (excel) {
            let cell = headerRow.getCell(i + 1);
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'cccccc'
                }
            }
            cell.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            cell.value = bandera ? data.headerName : data;
            cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
        //}
        i++;
    })

    rowData.map(async function(data){
        if (data.Cont20FtDeck=="DECK") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:F7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20FtDeck;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("G7:J7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont20FtHold;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("K7:N7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont20FtTotal;

            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }

        } else if (data.Cont20Full == "20F") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            worksheet.mergeCells("C6:C7");
            //Grupo 1
            worksheet.mergeCells("D7:E7");
            let cellA4 = worksheet.getCell("D7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:G7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.Cont20Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("H7:I7");
            cellA4 = worksheet.getCell("H7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("J7:K7");
            cellA4 = worksheet.getCell("J7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.Cont40Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 5
            worksheet.mergeCells("L7:M7");
            cellA4 = worksheet.getCell("L7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Full;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 6
            worksheet.mergeCells("N7:O7");
            cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.Cont45Empty;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.CtaFunctionCode == "CONTACT INFORMATION") {
            //Grupo 1
            worksheet.mergeCells("N7:P7");
            let cellA4 = worksheet.getCell("N7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.CtaFunctionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("Q7:R7");
            cellA4 = worksheet.getCell("R7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                }
            }
            cellA4.value = data.ComAddressIdentifier;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 3
            worksheet.mergeCells("S7:V7");
            cellA4 = worksheet.getCell("S7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = data.HanDescriptionCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 4
            worksheet.mergeCells("W7:Y7");
            cellA4 = worksheet.getCell("W7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '5d6164'
                }
            }
            cellA4.value = data.TsrServicePriorityCode;
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        } else if (data.section=="totalLoad") {
            //Grupo 0
            worksheet.mergeCells("A6:A7");
            worksheet.mergeCells("B6:B7");
            //Grupo 1
            worksheet.mergeCells("C7:E7");
            let cellA4 = worksheet.getCell("C7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: '1a3760'
                },
            }
            cellA4.value = "DECK";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
            //Grupo 2
            worksheet.mergeCells("F7:H7");
            cellA4 = worksheet.getCell("F7");
            cellA4.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
            cellA4.font = {
                name: "Calibri",
                family: 4,
                underline: false,
                bold: true,
                color: { argb: 'ffffff' },
            };
            cellA4.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'ec631b'
                }
            }
            cellA4.value = "HOLD";
            cellA4.border = {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            }
        }else {
            let dataRow = worksheet.addRow();
            let indice = 0;

            keyColumns.map(function (data2) {
                let cell = dataRow.getCell(indice + 1);
                cell.value = data[data2];
                if (indice!=0 && !isNaN(data[data2])) {
                    cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
                }
                //if (cell.value==)
                cell.border = {
                    top: { style: 'thin' },
                    bottom: { style: 'thin' },
                    left: { style: 'thin' },
                    right: { style: 'thin' },
                };
                cell.alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' }
                indice++;
            })
        }
    })

    let fileName = documento;
    let today = DateFormater.formatDateTimeWithSlashToOnlyDate(new Date());
    today = today.replaceAll("/","");
    let time = new Date();
    time = time.getHours().toString() + time.getMinutes().toString() + time.getSeconds().toString();
    //return false;
    if (excel) {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.xlsx`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    } else {
        workbook.csv.writeBuffer()
            .then(buffer => {
                saveAs(new Blob([buffer]), `${fileName}${today}${time}.csv`)
                this.$notify({
                    group: 'container',
                    text: this.$t('label.reportSuccessfully'),
                    type: "success"
                });
            })
    }
}

function numeroLetra(valor) {
    const abc = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BK", "BL", "BM", "BN", "BO", "BP", "BQ", "BR", "BS", "BT", "BU", "BV", "BW", "BX", "BY", "BZ"];

    return abc[valor - 1];
}

export default {
    data,
    methods: {
        getPdf,
        getReporExcel,
        getReporExcelCanceled,
        getExcel,
        numeroLetra,
    },
    computed:{
      ...mapState({
          dataContainer: state => state.yardManagement.dataContainer,
          user: state => state.auth.user,
          branch: state => state.auth.branch,
          yardData: state => state.yardManagement.yardData,
      }),
    },
  }