var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==0),expression:"showIndex==0"}]},[_c('div',{staticClass:"align-items-center justify-content-center mx-1 my-3",staticStyle:{"background-color":"white","color":"#828c9c","height":"auto"}},[_c('CRow',{staticClass:"align-items-center justify-content-center"},[_c('CCol',{staticClass:"align-items-center",attrs:{"sm":"6","xl":"6"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12","xl":"12"}},[_c('b',[_vm._v(_vm._s(_vm.$t('EVENTOS / TRASEGADO')))])])],1)],1),_c('CCol',{attrs:{"sm":"6","xl":"6"}},[_c('CRow',[_c('CCol',{staticClass:"align-items-center",attrs:{"sm":"12","xl":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('TRASEGADO'),
                  placement: 'top-end'
                }),expression:"{\n                  content: $t('TRASEGADO'),\n                  placement: 'top-end'\n                }"}],staticClass:"d-flex align-items-center ml-auto",attrs:{"shape":"square","color":"add"},on:{"click":function($event){return _vm.toggleAdd({})}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nueva')))])],1)],1)],1)],1)],1)],1),_c('div',[_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","details":"","column-filter":"","items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"pagination":""},scopedSlots:_vm._u([{key:"number",fn:function({ index }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index+1)+" ")])]}},{key:"status",fn:function({item}){return [_c('td',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.status)}},[_vm._v(_vm._s(item.status))])],1)]}},{key:"edit",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"edit","square":"","size":"sm"}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}},{key:"seals",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                  content: _vm.$t('label.Seals'),
                                  placement: 'top-end'
                              }),expression:"{\n                                  content: $t('label.Seals'),\n                                  placement: 'top-end'\n                              }"}],staticClass:"mr-1",staticStyle:{"background-color":"#d6d6d7"},attrs:{"square":"","size":"sm"},on:{"click":function($event){return _vm.viewDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"bottomSeals"}})],1)],1)]}},{key:"details",fn:function({item}){return [_c('CCollapse',{attrs:{"show":Boolean(item._toggled),"duration":_vm.collapseDuration}},[_c('CCardBody',[_c('dataTableExtended',{staticClass:"align-center-row-datatable table-lv-2",attrs:{"small":"","items":_vm.formatedSecondLevelItems,"fields":_vm.level2Fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage},scopedSlots:_vm._u([{key:"view",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"watch","square":"","size":"sm"},on:{"click":function($event){return _vm.viewDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]}},{key:"galery",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"add","square":"","size":"sm"},on:{"click":function($event){return _vm.viewDetail(item, index)}}},[_c('CIcon',{attrs:{"name":"cil-image"}})],1)],1)]}}],null,true)})],1)],1)]}}])})],1)],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==1),expression:"showIndex==1"}]},[_c('TrasegadoRegister',{attrs:{"Active":_vm.ActiveRegister,"ContainerItem":_vm.ContainerItem},on:{"Close":function($event){_vm.showIndex=0,_vm.ActiveRegister=false},"Update-list":_vm.Update}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }