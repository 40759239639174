import { required } from 'vuelidate/lib/validators';
import { DateFormater } from "@/_helpers/funciones";

export default (PreviousDate, LaterDate) => {
    return {
        TpEirName:{required},
        FormatedDate: {required, RangeDate(value){
            return(DateFormater.formatDateTimeWithoutSlash(value) >= PreviousDate &&
                DateFormater.formatDateTimeWithoutSlash(value) <= LaterDate)
        }},
        ClientId: {required},
        Observation: {},
        TpTransacEirName: {},
        CarrierId: {required},
        CarrierName: {},
        VehicleId: {required},
        DriverName: {},
        Devolution: {},
        SearchDriver: {},
        SearchPlate: {},
        LicensePlate: {},
        DriverId: {required},
        OperatorName: {},
        EirContainerCondictionId: {required},
    }
}