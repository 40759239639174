<template>
    <div>
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CCard class="justify-content-center">
          <CCardHeader class="bg-dark text-white">
            <CRow>
              <CCol sm="10" class="d-flex mt-1">{{title}}</CCol>
              <CCol sm="2" class="d-flex justify-content-end">
                <button type="button" aria-label="Close" class="close" @click="toggle(false)">x</button>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody> 
            <CRow class="d-flex justify-content-center">
                <CCol sm="12" lg="5">
                    <CRow>
                        <CCol sm="12" lg="12">
                            <CSelect
                            :label="$t('label.vehicleClasification')"
                            :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                            add-label-classes="text-right"
                            v-uppercase
                            size="sm"
                            :options="computedClassVehicle"
                            :value="VehicleClassifId"
                            @change="slectedCLassIdVehicle($event)"
                            :addInputClasses="{ 'is-invalid': $v.VehicleClassifId.$error }"
                            :invalid-feedback="errorMessage($v.VehicleClassifId)"
                            :is-valid="hasError($v.VehicleClassifId)"
                            v-model.trim="$v.VehicleClassifId.$model"
                            />
                        </CCol>
                        <CCol sm="12" lg="12">
                            <CSelect
                            :label="$t('label.brand')"
                            :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                            add-label-classes="text-right"
                            v-uppercase
                            size="sm"
                            :options="computedBrandList"
                            :value="BrandId"
                            @change="selesctBrand($event)"
                            :addInputClasses="{ 'is-invalid': $v.BrandId.$error }"
                            :invalid-feedback="errorMessage($v.BrandId)"
                            :is-valid="hasError($v.BrandId)"
                            v-model.trim="$v.BrandId.$model"
                            />
                        </CCol>
                        <CCol sm="12" lg="12">
                            <CSelect
                            :label="$t('label.model')"
                            :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                            add-label-classes="text-right"
                            v-uppercase
                            size="sm"
                            :options="computedModelList"
                            :value="ModelId"
                            @change="selectModel($event)"
                            :disabled="this.BrandId === '' || this.BrandId === 'N/A'"
                            :addInputClasses="{ 'is-invalid': $v.ModelId.$error }"
                            :invalid-feedback="errorMessage($v.ModelId)"
                            :is-valid="hasError($v.ModelId)"
                            v-model.trim="$v.ModelId.$model"
                            />
                        </CCol>
                        <CCol sm="12" v-if="editModal">
                            <CSelect
                            :label="$t('label.status')"
                            :horizontal="{label: 'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                            add-label-classes="text-right"
                            v-uppercase
                            size="sm"
                            :options="optionsStatus"
                            :invalid-feedback="errorMessage($v.Status)"
                            :is-valid="statusSelectColor()"
                            :value.sync="Status"
                            v-model.trim="$v.Status.$model"
                            />
                        </CCol>
                    </CRow>
                </CCol>   
                <CCol sm="12" lg="5">
                    <CRow>
                    <CCol sm="12" lg="12">
                        <CInput
                        :label="$t('label.vehiclePlate')"
                        :horizontal="{label: 'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                        add-label-classes="required text-right"
                        v-uppercase
                        size="sm"
                        :addInputClasses="{ 'is-invalid': $v.LicensePlate.$error }"
                        :invalid-feedback="errorMessage($v.LicensePlate)"
                        :is-valid="hasError($v.LicensePlate)"
                        v-model.trim="$v.LicensePlate.$model"
                        :value.sync="newVehiclePlate"
                        :placeholder="$t('label.vehiclePlate')"
                        @keypress="ejecutarEnter"
                        >
                        <template #append>
                            <CButton
                            v-if="!CallMaster"
                            color="watch" size="sm"
                            :disabled="newVehiclePlate == ''"
                            v-c-tooltip="{content: $t('label.search'),placement: 'top'}"
                            @click="buscarLicense"
                            >
                            <CIcon name="cil-search" />
                            </CButton>
                        </template>
                        </CInput>
                    </CCol>
                    <CCol sm="12" class="medio mb-3" v-if="collapse">
                        <CCollapse :show="collapse">
                        <CCard>
                            <CCardHeader>
                            <b class="w-75">{{$t('label.select')}}</b>
                            <CButton color="dark" 
                                class="float-right" 
                                size="sm" 
                                @click="collapse=false" >
                                X
                            </CButton>
                            </CCardHeader>
                            <CCardBody>
                            <dataTableExtended
                                class="align-center-row-datatable"
                                :items="items2"
                                :fields="fields"
                                column-filter
                                :noItemsView="tableText.noItemsViewText"
                                :table-filter="tableText.tableFilterText"
                                :items-per-page-select="tableText.itemsPerPageText"
                                :items-per-page="5"
                                hover
                                small
                                sorter
                                pagination
                                
                            >
                                <template #Seleccione="{item}">
                                <td class="text-center">
                                    <CButton color="add" size="sm" v-c-tooltip="{content: $t('label.select')}" @click="seleccionarDatos(item)" >
                                    <CIcon name="checkAlt" />
                                    </CButton>
                                </td>
                                </template>
                            </dataTableExtended>
                            </CCardBody>
                        </CCard>
                        </CCollapse>            
                    </CCol>
                    <CCol class="mb-0">
                        <div role="group" class="form-group form-row mb-0">
                        <label
                            class="text-right col-form-label-sm col-sm-12  col-lg-3"
                        >
                            {{ $t("label.year") }}
                        </label>
                        <div class="col-sm-12 col-lg-9 input-group-sm">
                            <vue-datepicker
                            :lang="$i18n.locale"
                            v-model="$v.Year.$model"
                            type="year"
                            value-type="format"
                            :show-second="false"
                            :clearable="false"
                            :disabled-date="fechaNoValida"
                            popup-class="vuedatapickerespecial1"
                            >
                            <template #input>
                                <CInput
                                class="w-100"
                                size="sm"
                                v-uppercase
                                v-model="$v.Year.$model"
                                placeholder="YYYY"
                                @blur="$v.Year.$touch()"
                                :addInputClasses="{ 'is-invalid': $v.Year.$error }"
                                :invalid-feedback="errorMessage($v.Year)"
                                :is-valid="hasError($v.Year)"
                                >
                                <template #append-content>
                                    <CIcon name="cil-calendar" />
                                </template>
                                </CInput>
                            </template>
                            <template #icon-calendar>
                                <div style="display: none"></div>
                            </template>
                            </vue-datepicker>
                        </div>
                        </div>
                    </CCol>
                    <CCol sm="12" lg="12">
                        <CInput
                        :label="$t('label.color')"
                        :horizontal="{label: 'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                        add-label-classes="text-right"
                        size="sm"
                        :addInputClasses="{ 'is-invalid': $v.Color.$error }"
                        :invalid-feedback="errorMessage($v.Color)"
                        :is-valid="hasError($v.Color)"
                        v-model.trim="$v.Color.$model"
                        v-uppercase
                        :placeholder="$t('label.color')"
                        >
                        </CInput>
                    </CCol>
                    </CRow>
                </CCol> 
                <CCol sm="12" lg="2">
                    <CRow>
                        <CCol sm="12">
                        <CButton 
                            size="sm" 
                            color="add"  
                            class="mr-1"  
                            v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
                            @click="editModal?statusConfirmation(originStatus, Status, submit, plate+' '+newVehiclePlate):submit()">
                            <CIcon name="checkAlt" />
                        </CButton>
            
                        <CButton 
                            color="wipe" 
                            class="mr-1" 
                            size="sm" 
                            v-c-tooltip="{content: $t('label.clearFields'), placement: 'top-end'}"   
                            @click.stop="cleanData" 
                            :disabled="isSubmit">
                            <CIcon name="cil-brush-alt"/>
                        </CButton>
            
                        </CCol>
                    </CRow>
                </CCol> 
            </CRow>
          </CCardBody>
        </CCard>
    </div>
    </template>
   <script>
   import UpperCase from '@/_validations/uppercase-directive';
   import GeneralMixin from '@/_mixins/general';
   import ModalMixin from '@/_mixins/modal';
   import { DateFormater } from '@/_helpers/funciones';
   import vehiculo from '@/_validations/chofer-vehiculo/vehiculo';
   import ChoferVehiculo from '@/_mixins/chofer-vehiculos';
   
   function data() {
     return {
       modalActive: false,
       loadingOverlay: false,
       loadingTable: false,
       optionsStatus: [
         { label: this.$t('label.ACTIVO'), value: 1 },
         { label: this.$t('label.INACTIVO'), value: 0 },
       ],
       items2:[],
       collapse:false,
       selected:false,
       Status: 1,
       originStatus: '',
       newVehiclePlate: '',
       itemsCLassVehicle: [],
       itemsBrandList: [],
       itemsModelList: [],
       VehicleClassifId: '',
       BrandId: '',
       ModelId: '',
       loadingModel: false,
       Year: '',
       Color: '',
       LicensePlate: '',
       VehicleId: '',
       isSubmit: false,  
     };
   }
   
   function fechaNoValida(date) {
     return date > new Date();
   }
   
   
   function buttonEnable() {
     if (this.VehicleClassifId === '') {
       return true;
     } else if (this.BrandId === '' || this.BrandId === 'N/A') {
       return true;
     } else if (this.ModelId === '' || this.ModelId === 'N/A') {
       return true;
     } else if (
       this.Year === '' ||
       this.Color === '' ||
       this.LicensePlate === ''
     ) {
       return true;
     } else {
       return false;
     }
   }
   function plate() {
     return this.$t('label.vehicle')+' '+this.$t('label.plate');
   }
   
   function selectedStatus(event) {
     this.Status = event.target.value;
   }
   
   function selesctBrand(event) {
     this.BrandId = event.target.value;
     this.mountedModelList(this.BrandId);
   }
   
   function selectModel(event) {
     this.ModelId = event.target.value;
   }
   
   function ejecutarEnter($event) {
     if (!this.CallMaster) {
       let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
     
       if (keyCode == 13) {
         this.buscarLicense();
       } else {
         this.collapse = false;
       }
     }
   }
   function seleccionarDatos(item) {
     this.VehicleId = item.VehicleId;
     this.VehicleClassifId = item.VehicleClassifId;
     this.BrandId = item.BrandId;
     this.ModelId = item.ModelId;
     this.LicensePlate = item.LicensePlate;
     this.Year = item.Year;
     this.Color = item.Color;
     this.DriverLicense = item.DriverLicense;
     this.Status = item.FgActVehicle ? 1 : 0;
     this.newVehiclePlate = item.LicensePlate;
     this.mountedClassVehicle();
     this.$v.$touch();
     this.collapse = false;
     //this.$nextTick(() => { this.$v.$touch() });
   }
   async function buscarLicense(){
     if (!this.collapse) {
       if(this.newVehiclePlate == '') return;
       let listado = Array;
       this.items2 = [];
       this.collapse = false;
       this.loadingOverlay = true;
       let LicensePlate = this.newVehiclePlate;
       await this.$http.get("Vehicle-by-LicensePlate", { LicensePlate:LicensePlate })
       .then(response => {
         listado = [...response.data.data];
         this.selected = true;
         if (listado.length == 1) {
           this.VehicleId = listado[0].VehicleId;
           this.VehicleClassifId = listado[0].VehicleClassifId;
           this.BrandId = listado[0].BrandId;
           this.ModelId = listado[0].ModelId;
           this.LicensePlate = listado[0].LicensePlate;
           this.Year = listado[0].Year;
           this.Color = listado[0].Color;
           this.originStatus = listado[0].Status === 'ACTIVO' ? 1 : 0;
           this.Status = listado[0].Status === 'ACTIVO' ? 1 : 0;
           this.newVehiclePlate= listado[0].LicensePlate;
           //this.ClientTpId = listado[0].StowagePlanningResourceId ? listado[0].StowagePlanningResourceId : this.ClientTpId;
           //this.mountedClassVehicle();
           this.$notify({
             group: 'container',
             text: this.$t('label.searchSuccesful'),
             type: "success"
           });
           this.$v.$touch();
         } else if (listado.length > 1) {
           this.$notify({
             group: 'container',
             text: this.$t('label.searchSuccesful'),
             type: "success"
           });
           let index = 1;
           this.items2 = listado.map(listado => Object.assign({}, this.items2, {
             ...listado,
             Nro: index++,
             UltimoUsuario: listado.TransaLogin,
             FormatedDate: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
             Status: listado.Status,
             FgActVehicle: listado.FgActVehicle,
             _classes: ( listado.FgActVehicle ) ? '' : 'table-danger'
           }));
           this.collapse = true;
         } else {
           this.$notify({
             group: 'container',
             title: '¡Mensaje!',
             text: this.$t('label.noResultsFound'),
             type: "error"
           });  
         }
         this.loadingOverlay = false;
       }).catch( err => {
         this.$notify({
           group: 'container',
           title: '¡Error!',
           text: err,
           type: "error"
         });
       })
       .finally(() => {
           this.loadingOverlay = false;
       });
     }
   }
   
   
   
   async function mountedClassVehicle() {
     //this.loadingOverlay = true;
     this.loadingOverlay = true;
   
     await this.$http
       .get('VehicleClassification-list?TpVehicleId=&Filter=ACTIVO')
       .then((response) => {
         if (response.data.data.data !== null) {
           this.itemsCLassVehicle = response.data.data;
   
           const temporalCLassVehicle = {
             VehicleClassifName: this.$t('label.select'),
             VehicleClassifId: '',
           };
           this.itemsCLassVehicle = [temporalCLassVehicle, ...this.itemsCLassVehicle];
           if (!this.editModal) {
             this.VehicleClassifId = this.itemsCLassVehicle[0].VehicleClassifId;
           } else {
             //this.mountedClassVehicle(this.VehicleClassifId);
           }
         }
   
         this.totalPages = response.data.data.TotalRecords;
       })
       .finally(() => {
         this.loadingOverlay = false;
       })
       .catch((e) => {
         this.loadingOverlay = false;
         this.$notify({
           group: 'container',
           title: '¡Error!',
           text: e,
           type: 'error',
         });
       });
   
     await this.mountedBrandList();
   }
   
   function computedClassVehicle() {
     if (this.itemsCLassVehicle.length > 0) {
       return this.itemsCLassVehicle.map((item) => {
         return {
           label: item.VehicleClassifName,
           value: item.VehicleClassifId,
         };
       });
     }
   }
   
   
   async function mountedBrandList() {
     //this.loadingOverlay = true;
     this.loadingOverlay = true;
   
     await this.$http
       .get('BrandVehicle-list?Filter=ACTIVO')
       .then((response) => {
         if (response.data.data.data !== null) {
           this.itemsBrandList = response.data.data;
           const temporalBrand = {
             BrandName: this.$t('label.select'),
             BrandId: '',
           };
           this.itemsBrandList = [temporalBrand, ...this.itemsBrandList];
           if (!this.editModal) {
             this.BrandId = this.itemsBrandList[0].BrandId;
           }else {
             if (this.BrandId!='' && this.BrandId!=null ) {
               this.mountedModelList(this.BrandId);
             }
           }
         }
   
         this.totalPages = response.data.data.TotalRecords;
       })
       .finally(() => {
         this.loadingOverlay = false;
       })
       .catch((e) => {
         this.loadingOverlay = false;
         this.$notify({
           group: 'container',
           title: '¡Error!',
           text: e,
           type: 'error',
         });
       });
   }
   
   function computedBrandList() {
     if (this.itemsBrandList.length > 0) {
       return this.itemsBrandList.map((item) => {
         return {
           label: item.BrandName,
           value: item.BrandId,
         };
       });
     }
   }
   
   async function mountedModelList(id) {
     if (this.BrandId!='') {
       this.loadingOverlay = true;
       //this.loadingModel = true;
       await this.$http
         .get(`Model-list?BrandId=${id}&Filter=ACTIVO`)
         .then((response) => {
           if (response.data.data.data !== null) {
             this.itemsModelList = response.data.data;
             if (!this.editModal) {
               //this.ModelId = this.itemsModelList[0].ModelId;
             }
           }
   
           this.totalPages = response.data.data.TotalRecords;
         })
         .finally(() => {
           //this.loadingModel = false;
           this.loadingOverlay = false;
         })
         .catch((e) => {
           //this.loadingModel = false;
           this.loadingOverlay = false;
   
           this.$notify({
             group: 'container',
             title: '¡Error!',
             text: e,
             type: 'error',
           });
         });
     }else{
       this.itemsModelList=[];
     }
   }
   
   function computedModelList(){
     if(this.itemsModelList.length === 0){
       return [{
         value: '', 
         label: this.$t('label.select'),
       }];
     }else{
       var chart = [{
         value: '', 
         label: this.$t('label.select'),
       }]
       this.itemsModelList.map(function(e){
         chart.push({
           label: e.ModelName,
           value: e.ModelId,
         })    
         })
         return chart;
     }
   }
   
   function slectedCLassIdVehicle(event) {
     this.VehicleClassifId = event.target.value;
   }
   
   async function submit(){
     try {
       this.loadingOverlay = true;
       this.isSubmit = true;
       this.$v.$touch();
   
       if (this.$v.$error) {
        this.loadingOverlay = false;
        this.isSubmit = false;
         throw this.$t('label.errorsPleaseCheck');
   
       }
       
       let VehicleJson = {
         VehicleClassifId: this.VehicleClassifId,
         BrandId: this.BrandId,
         ModelId: this.ModelId,
         LicensePlate: this.LicensePlate,
         Year: this.Year,
         Color: this.Color,
       };
   
       if(this.editModal){
         VehicleJson.Status= this.Status;
         VehicleJson.VehicleId= this.VehicleId;
       };
   
       let method = this.editModal? 'PUT':'POST';
       let ruta = this.editModal? 'Vehicle-update':'Vehicle-insert';
   
       await this.$http
         .ejecutar (method, ruta, VehicleJson, { root: 'VehicleJson' })
         .then((response) => {
           if (response.status === (200 || 201)) {
             let messageSuccess = response.data.data[0].Response;
             if (this.CallMaster) {
               this.$emit('UpdatedVehicle', response.data.data[0]);
             }
             this.cleanData();
             this.toggle(false);
             this.notifySuccess({ text: messageSuccess });
           }
         })
         .catch((e) => {
           this.notifyError({ text: e });
         }).then(() => {
            this.loadingOverlay = false;
            this.isSubmit = false;
          });
     } catch (e) {
       this.notifyError({ text: e });
     }
   }
   
   function resetForm(event) {
     this.VehicleClassifId = '';
     this.BrandId = '';
     this.ModelId = '';
     this.LicensePlate = '';
     this.Year = '';
     this.Color = '';
     this.Status = 1;
     this.originStatus = '';
     this.$v.$reset();
     this.$emit('updated-modal', event);
   }

   function cleanData() {
    this.VehicleClassifId = '';
     this.BrandId = '';
     this.ModelId = '';
     this.LicensePlate = '';
     this.Year = '';
     this.Color = '';
     this.Status = 1;
     this.originStatus = '';
     this.$v.$reset();
   }
   
   function optionListYear(){
    
     const chart = [
       {
         value:"",
         label: this.$t('label.select')
       },
     ];
     var fecha = new Date();
     var ano = fecha.getFullYear();
     let n = 1950;
       while (n < ano) {
         n ++;
         chart.push(n)
         }
         return chart;
     }
     function fields(){
       return [
         { key: 'Nro', label: '#', _style: 'width:1%;', filter: false, _classes: 'text-center',sorter: true },
         { label: this.$t('label.brand'), key: 'BrandName', _classes:'text-uppercase' },
         { label: this.$t('label.type'), key: 'VehicleClassifName', _classes:'text-uppercase' },   
         { label: this.$t('label.vehiclePlate'), key: 'LicensePlate', _classes:'text-uppercase'},
         { key: 'UltimoUsuario', label: this.$t('label.user'),_classes:"center-cell",sorter: true },
         { key: 'FormatedDate', label:this.$t('label.date'), _classes:'center-cell',_style:'width:10%;',sorter: true},
         { key: "Status", label: this.$t("label.status"), _classes: "text-center" },
         { key: 'Seleccione', label: '', _style: 'width:5%;', filter: false,sorter: false },
       ];
   }
   export default {
     name: 'modalVehiculo',
     data,
     props: {
       title: String,
       modal: null,
       editModal: Boolean,
       objectVehicle: Object,
       CallMaster: Boolean,
     },
     mixins: [GeneralMixin, ModalMixin, ChoferVehiculo],
     directives: UpperCase,
     watch: {
       modal: function(val) {
         this.modalActive = val;
         if (val === true) {
           this.mountedClassVehicle();
           if(this.editModal||this.CallMaster){
             this.VehicleId = this.objectVehicle.VehicleId ?? '';
             this.VehicleClassifId = this.objectVehicle.VehicleClassifId ?? '';
             this.BrandId = this.objectVehicle.BrandId ?? '';
             this.ModelId = this.objectVehicle.ModelId ?? '';
             this.LicensePlate = this.objectVehicle.LicensePlate ?? '';
             this.Year = this.objectVehicle.Year ?? '';
             this.Color = this.objectVehicle.Color ?? '';
             this.originStatus = this.objectVehicle.Status === 'ACTIVO' ? 1 : 0;
             this.Status = this.objectVehicle.Status === 'ACTIVO' ? 1 : 0;
             this.newVehiclePlate= this.objectVehicle.LicensePlate  ?? '';
             this.editModal ? this.$v.$touch() : '';
           }
         }
       },
     },
     methods: {
       mountedClassVehicle,
       slectedCLassIdVehicle,
       mountedBrandList,
       mountedModelList,
       selectModel,
       ejecutarEnter,
       seleccionarDatos,
       buscarLicense,
       selesctBrand,
       selectedStatus,
       submit,
       cleanData,
       resetForm,
       fechaNoValida,
     },
     validations: vehiculo,
     computed: {
       computedClassVehicle,
       computedBrandList,
       computedModelList,
       buttonEnable,
       plate,
       optionListYear,
     },
   };
   </script>
   <style lang="scss">
   
   .modal-content-vehiculo {
     .modal-content {
       width: 85% !important;
       margin: auto !important;
       .modal-body {
         padding-left: 1.7rem;
         padding-right: 1.7rem;
       }
     }
   } 
   </style>
   
   