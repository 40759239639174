<template>
    <div>
        <CCollapse :show="activeItem(1)">
            <Emptied/>
        </CCollapse>
        <CCollapse :show="activeItem(2)">
            <Implements/>
        </CCollapse>
        <CCollapse :show="activeItem(3)">
            <Trasegado/>
        </CCollapse>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import Emptied from './emptied/emptied-index';
    import Implements from './implements/implements-index';
    import Trasegado from './trasegado/trasegado-index';
    //methods
    function activeItem(item) {
        return this.dropItemEvent === item;
    }
    //watch
    /*
    function tabIndex(newValue,OldValue){
        if(OldValue === 2){
            this.$store.state.visitas.dropItemAgenda = 0;
        }
    }/**/ 
    export default {
        name:"events-index",
        methods:{
            activeItem,
        },
        components:{
            Emptied,
            Implements,
            Trasegado
        },
        computed:{
            ...mapState({
                tabIndex: state => state.yard-management.tabMovementIndex,
                dropItemEvent: state => state.yardManagement.dropItemEvent,
            })
        },
        watch:{
            //tabIndex
        }
    }
</script>