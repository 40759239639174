<template>
    <div>
        <CCollapse :show="activeItem(1)">
            <DamagesList/>
        </CCollapse>
        <CCollapse :show="activeItem(2)">
            <SealsList/>
        </CCollapse>
        <CCollapse :show="activeItem(3)">
            <Recognition/>
        </CCollapse>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import DamagesList from './damages/damage-index';
    import SealsList from './seals/seal-index';
    import Recognition from './recognition/recognition-index';

    //methods
    function activeItem(item) {
        return this.dropItemInspection === item;
    }

    export default {
        name:"inspections-index",
        methods:{
            activeItem,
        },
        components:{
            DamagesList,
            SealsList,
            Recognition,
        },
        computed:{
            ...mapState({
                tabIndex: state => state.yard-management.tabMovementIndex,
                dropItemInspection: state => state.yardManagement.dropItemInspection,
            })
        },
        watch:{
            //tabIndex
        }
    }
</script>