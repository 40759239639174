import { required } from 'vuelidate/lib/validators';
import { nameLength, onlyAlphanumeric5 } from '@/_validations/validacionEspeciales'
export default () => {
    return {
        TpCargoViewId: { required },
        TpDamageId: { required },
        Responsible: { nameLength },
        Width: {},
        Height: {},
        Observation: { onlyAlphanumeric5 },
    }
}