<template>
    <CModalExtended
      :title="title"
      color="dark"
      size="lg"
      :close-on-backdrop="false"
      :show.sync="modalActive"
      class="modal-content-mov"
    >
      <form @submit.stop.prevent="submit" class="company-form">
        <CRow>
          <CCol sm="12">
            <CCard class="card-simple">
              <CCardBody>
                <CRow>
                  <CCol sm="12">
                    <CTextarea
                      v-uppercase
                      v-model.trim="$v.observation.$model"
                      :is-valid="hasError($v.observation)"
                      :invalid-feedback="errorMessage($v.observation)"
                      :horizontal="{ input: 'col-sm-12 col-lg-12'}"
                      :placeholder="$t('label.observation')"
                      addLabelClasses="text-right"
                      rows="4"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>         
      </form>
      <template #footer>
        <CButton
          shape="square"
          color="add"
          class="d-flex align-items-center"
          @click.stop="deleteDocument()"
        >
          <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
        </CButton>
        <CButton
          shape="square"
          color="wipe"
          class="d-flex align-items-center"
          @click.stop="toggle(false)"
        >
          <CIcon name="x"/>
          <span class="ml-1">{{ $t('button.exit') }}</span>
        </CButton>
      </template>
    </CModalExtended>
    </template>
    
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import { mapState } from 'vuex';
import { required, maxLength, helpers } from 'vuelidate/lib/validators';

//Data
function data() {
  return {
    modalActive: this.modal,
    observation: '',
  }
}

//Methods
function toggle(newVal) {
  this.modalActive = newVal;
}

function deleteDocument() {
  try {
    this.$v.$touch();
    this.$store.state.yardManagement.loading = true;
    if (this.$v.$error) {
      this.$store.state.yardManagement.loading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let EirJson = {
        YardCargoEirId: this.YardCargoEirId,
        Observation: this.observation
    }

    this.$http
      .put('YardCargoEir-deactivate', EirJson, { root: 'EirJson' })
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          const messageSuccess = response.data.data[0].Response;
          const data = response.data.data[0];
          this.$store.state.yardManagement.loading = false;
          this.toggle(false);
          this.$emit('Update-list');
          this.notifySuccess({text: messageSuccess})
        } 
      }).catch((e) => {
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: e});
  }
}

//Computeds:

export default {
    name: 'observation-modal',
    mixins: [
    ModalMixin,
    GeneralMixin
    ],
    props: {
      modal: Boolean,
      title: String,
      YardCargoEirId: String
    },
    data,
    directives: UpperCase,
    methods: {
      toggle,
      deleteDocument
    },
    validations: {
      observation: { required},
    },
    computed: {
      ...mapState({
        loading: state => state.yardManagement.loading,
      })
    },
    watch: {
        modal: function(val) {
            this.toggle(val);
            this.observation = '';
            this.$v.$reset();
        },
        modalActive: function(val) {
            this.$emit('update:modal', val);
        }
    }
}
</script>
<style lang="scss">
  .card-simple{
      border: 0px;
      margin-bottom: 0 !important;
  }
  .modal-content-mov {
      .modal-content {
      width: 70% !important;
      margin: auto !important;
      .modal-body {
          padding-left: 1.7rem;
          padding-right: 1.7rem;
      }
      }
  }
</style>