<template>
  <div>
    <div v-show="showIndex==0">
      <CRow class="mb-3">
        <CCol sm="12" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.dispatchList')}`}} </h6>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12" lg="12" class="align-items-end mb-3">
          <CButton
              v-if="ValidateReception"
              shape="square"
              color="add"
              class="d-flex align-items-center ml-auto"
              v-c-tooltip="{
                content: $t('label.dispatchContainer'),
                placement: 'top-end'
              }"
              @click="toggleAdd({}, false)"
            >
              <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
            </CButton>
        </CCol>
      </CRow>
      <div>
        <CRow>
            <CCol col="12" xl="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    details
                    column-filter
                    :items="formatedItems"
                    :fields="fields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="tableText.itemsPerPage"
                    pagination
                >
                    <template #Status="{item}">
                      <td class="text-center">
                        <CBadge :color="getBadge(item.status)">{{item.Status}}</CBadge>
                      </td>
                    </template>
                    <template #button="{item}">
                      <td class="text-center">
                        <CButton
                          color="edit"
                          square
                          size="sm"
                          class="mr-1"
                          @click="toggleAdd(item, true)"
                          v-c-tooltip="{
                            content: `${$t('label.edit')} ${$t('label.dispatch')}`,
                            placement: 'top-start'
                          }"
                        >
                          <CIcon name="pencil" />
                        </CButton>
                        <CButton
                          square
                          style="background-color: #d6d6d7;"
                          size="sm"
                          class="mr-1"
                          v-c-tooltip="{
                              content: $t('label.Seals'),
                              placement: 'top-start'
                          }"
                          @click="viewSealsDetail(item)"
                        >
                          <CIcon name="bottomSeals"/>
                        </CButton>
                        <CButton
                          square
                          style="background:#d6d6d7"
                          size="sm"
                          class="mr-1"
                          v-c-tooltip="{
                              content: $t('label.documentation'),
                              placement: 'top-start'
                          }"
                          @click="viewDocumentationDetail(item)"
                        >
                          <CIcon name="bottomDocumentation"/>
                        </CButton>
                        <CButton
                          square
                          style="background:#e0e0e0"
                          size="sm"
                          class="mr-1"
                          v-c-tooltip="{
                              content: $t('label.logistics'),
                              placement: 'top-start'
                          }"
                          @click="viewLogisticDetail(item)"
                        >
                          <CIcon name="bottomLogistic"/>
                        </CButton>
                      </td>
                    </template>
                    <template #details="{item}">
                      <CCollapse
                        :show="Boolean(item._toggledSeals)"
                        :duration="collapseDuration"
                      >
                        <CCardBody>
                          <dataTableExtended
                            class="align-center-row-datatable table-lv-2"
                            small
                            :items="formatedSealsItems(item?.SealJson)"
                            :fields="sealsFields"
                            :noItemsView="tableText.noItemsViewText"
                            :items-per-page="tableText.itemsPerPage"
                          >
                            <template #button="{item}">
                              <td class="text-center">
                                <CButton 
                                  v-if="item.SealDocumentJson.length!=0&&item.Seal"
                                  color="watch"
                                  square
                                  size="sm"
                                  class="mr-1"
                                  @click="ModalImagen(item)"
                                >
                                  <CIcon name="eye"/>
                                </CButton>
                              </td>
                            </template>
                          </dataTableExtended>
                        </CCardBody>
                      </CCollapse>

                      <CCollapse
                        :show="Boolean(item._toggledDocumentation)"
                        :duration="collapseDuration"
                      >
                        <CCardBody>
                          <dataTableExtended
                            class="align-center-row-datatable table-lv-2"
                            small
                            :items="formatedDocumentation(item)"
                            :fields="documentationFields"
                            :noItemsView="tableText.noItemsViewText"
                            :items-per-page="tableText.itemsPerPage"
                        >
                          <template #ViewEir="{item}">
                            <td class="text-center">
                              <CButton
                                v-if="item.EirDocumentJson.length != 0"
                                color="watch"
                                square
                                size="sm"
                                @click="ViewEir(item)"
                              >
                                <CIcon name="eye"/>
                              </CButton>
                            </td>
                          </template>
                          <template #ViewTicketOut="{item}">
                            <td class="text-center">
                              <CButton
                                v-if="item.OutPassDocumentJson.length != 0"
                                color="watch"
                                square
                                size="sm"
                                @click="ViewTicketOut(item)"
                              >
                                <CIcon name="eye"/>
                              </CButton>
                            </td>
                          </template>
                        </dataTableExtended>
                        </CCardBody>
                      </CCollapse>

                      <CCollapse
                        :show="Boolean(item._toggledLogistic)"
                        :duration="collapseDuration"
                      >
                        <CCardBody>
                          <dataTableExtended
                            class="align-center-row-datatable table-lv-2"
                            small
                            :items="formatedLogisticItems(item)"
                            :fields="logisticFields"
                            :noItemsView="tableText.noItemsViewText"
                            :items-per-page="tableText.itemsPerPage"
                        >
                        </dataTableExtended>
                        </CCardBody>
                      </CCollapse>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
      </div>
    </div>
    <div v-show="showIndex==1">
      <DispatchRegister
        :Active="showIndex==1"
        :ContainerItem="ContainerItem"
        :isEdit="isEdit"
        @Close="showIndex=0,ContainerItem={}"
        @Update-list="Update"
        @UpdateDispatch="getYardEventDispatch"
      />
    </div>
    <div v-show="showIndex==2">
      <PositioningRegister 
        :Active="showIndex==2"
        :isEdit="false"
        @Close="showIndex = 0"
        @Update-list="ConainerUpdate"
      />
    </div>
    <ModalImagesList
      :modal="ModalImages"
      :Images="ImagesList"
      :titleModal="ImagenModalTitle"
      @Close="ModalImages=false,ImagesList=[],ImagenModalTitle=''"
    />
    <ModalSealImages
      :modal="ModalSeal"
      :labelModal="ImagenModalTitle"
      :SealImages="ImagesList"
      @Close="(ModalSeal=false, ImagenModalTitle='', ImagesList=[])"
    />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import DispatchRegister from './dispatch-register';
import ModalImagesList from './modal-images-list';
import ModalSealImages from '../../modal-seal-images';
import PositioningRegister from '../container-positioning/positioning-register';
import { mapState } from 'vuex';
import { DateFormater } from '@/_helpers/funciones';

//DATA-------------------------
function data() {
  return {
    ModalSeal: false,
    ModalImages: false,
    isEdit: false,
    ImagenModalTitle: '',
    ImagesList: [],
    collapseDuration: 0,
    _toggledSeals: false,
    _toggledDocumentation: false,
    _toggledLogistic: false,
    showIndex: 0,
    ContainerItem:{},
    dataDispatch :[],
  }
}

//methods
async function getYardEventDispatch() {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardEventDispatch-by-CargoId', { CargoId: this.CargoId })
    .then(response => {
      let List = response.data.data;
      this.dataDispatch = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(()=>{
      this.$store.state.yardManagement.loading = false;
    });
}

async function getYardCargo(value) {
  await this.$http.get('YardCargo-by-Id', { YardCargoId: this.YardCargoId, YardId: this.YardId })
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {

        this.$store.state.yardManagement.dataContainer = {...List[0], YardCargoId : value.YarCargoOriginId, YarCargoDispachtId: value.YardCargoId};
        
        switch (List[0].YardCargoStatus.toUpperCase()) {
          case process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION:{
            if (this.GeneralList==1) {
              let Data = this.$store.state.yardManagement.ListReceivedContainers.find(Received =>{
                return Received.CargoId == this.CargoId
              });
              if (!Data) {
                this.$store.state.yardManagement.ListReceivedContainers.unshift(List[0]);
              }
            }else if(this.GeneralList==2){
              this.$store.state.yardManagement.ListDispatchedContainers = 
                this.$store.state.yardManagement.ListDispatchedContainers.filter(item => item.CargoId != this.CargoId);
            }
            this.$store.state.yardManagement.globalSelectContainer.unshift(List[0]);
            this.showIndex = 2;
            break;
          }
          case process.env.VUE_APP_YARD_CARGO_STATUS_DISPACHED:{
            this.$store.state.yardManagement.containerSelected = '';
            this.$store.state.yardManagement.globalSelectContainer = 
              this.$store.state.yardManagement.globalSelectContainer.filter(item => item.CargoId != this.CargoId);
            if (this.GeneralList == 2) {
              let data = this.$store.state.yardManagement.ListDispatchedContainers.find(Dispatched =>{
                return Dispatched.CargoId == this.CargoId
              });
              if (!data) {
                this.$store.state.yardManagement.ListDispatchedContainers.unshift(List[0]);
              }else{
                this.$store.state.yardManagement.ListDispatchedContainers = 
                  this.$store.state.yardManagement.ListDispatchedContainers.map(Dispatched=>{
                    return Dispatched.CargoId == this.CargoId ? List[0] : Dispatched;
                  });
              }
            }else if(this.GeneralList == 1){
              this.$store.state.yardManagement.ListReceivedContainers = 
                this.$store.state.yardManagement.ListReceivedContainers.filter(item => item.CargoId != this.CargoId);
            }else if(this.GeneralList == 3){
              this.$store.state.yardManagement.ListRecognitionContainers = 
                this.$store.state.yardManagement.ListRecognitionContainers.map(Recognition=>{
                  return Recognition.CargoId == this.CargoId ? List[0] : Recognition;
                });
            }
            break;
          }
        }
      }else{
        this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListDispatchedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$store.state.yardManagement.ListReceivedContainers = [];
      this.$store.state.yardManagement.ListDispatchedContainers = [];
      this.$store.state.yardManagement.ListRecognitionContainers = [];
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardManagementCurrent() {
  await this.$http.get('YardManagementCurrent-by-Id', { YardCurrentId: this.YardCurrentId })
    .then(response => {
      if(response.data.data){
            let yardInfo = this.$store.state.yardManagement.yardData;
            yardInfo = {
                ...yardInfo,
                YardName : response.data.data[0].YardName,
                YardAlias : response.data.data[0].YardAlias,
                SquareMeters : response.data.data[0].SquareMeters,
                TpYardNameEn : response.data.data[0].TpYardNameEn,
                TpYardNameEs : response.data.data[0].TpYardNameEs,
                TeusCapacity : response.data.data[0].TeusCapacity,
                TeusOccupied : response.data.data[0].TeusOccupied,
                TeusAvailable: response.data.data[0].TeusAvailable,
                YardGpoName : response.data.data[0].YardGpoName,
                TodayReception : response.data.data[0].TodayReception,
                TodayDispatch : response.data.data[0].TodayDispatch
            }
            this.$store.state.yardManagement.yardData = yardInfo;
        }   
    }).catch( err => {
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
    })
}

async function ConainerUpdate() {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardCargo-by-Id', { YardCargoId: this.YardCargoId, YardId: this.YardId })
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {
        this.$store.state.yardManagement.dataContainer = List[0];
        if (process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION == List[0].YardCargoStatus.toUpperCase()) {
          this.$store.state.yardManagement.globalSelectContainer = 
            this.$store.state.yardManagement.globalSelectContainer.map(Select=>{
              return Select.YardCargoId == this.YardCargoId ? List[0] : Select;
            });
        }
      }else{
        this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$store.state.yardManagement.ListReceivedContainers = [];
      this.$store.state.yardManagement.ListRecognitionContainers = [];
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).finally(()=>{
      this.$store.state.yardManagement.loading = false;
    })
}

function viewSealsDetail(item){
  item._toggledSeals = !item._toggledSeals;
  item._toggledDocumentation = false;
  item._toggledLogistic = false;
  this.collapseDuration = 300;
  this.$nextTick(() => {
    this.collapseDuration = 0;
    this.loading = false;
    this.apiStateLoading = false;
  });
}

function viewDocumentationDetail(item){
  item._toggledSeals = false;
  item._toggledDocumentation = !item._toggledDocumentation;
  item._toggledLogistic = false;
  
  this.collapseDuration = 300;
  this.$nextTick(() => {
    this.collapseDuration = 0;
    this.loading = false;
    this.apiStateLoading = false;
  });
}

function viewLogisticDetail(item){
  item._toggledSeals = false;
  item._toggledDocumentation = false;
  item._toggledLogistic = !item._toggledLogistic;
  this.collapseDuration = 300;
  this.$nextTick(() => {
    this.collapseDuration = 0;
    this.loading = false;
    this.apiStateLoading = false;
  });
}

function formatedSealsItems(SealJson) {
  if (SealJson&&SealJson.length!=0) {
    let _lang = this.$i18n.locale;
    let SealArray = SealJson[0].Seal1Json.concat(SealJson[0].Seal2Json, SealJson[0].Seal3Json, SealJson[0].Seal4Json);
    return SealArray.map((item) => {
      return {
        ...item,
        Seal: item.Seal ? item.Seal : '',
        SealConditionName: _lang=='en' ? item.SealConditionNameEn || '' : item.SealConditionNameEs || '',
        SealB: item.SealB ? item.SealB : '',
        SealAssignmentName: _lang=='en' ? item.SealAssignmentNameEn || '' : item.SealAssignmentNameEs || '',
        ObservationSeal: item.ObservationSeal ? item.ObservationSeal : '',
      };
    })
  }
}

function formatedDocumentation(Documentation) {
  if(["", null, undefined].includes(Documentation.EirNumber) && ["", null, undefined].includes(Documentation.ViewEir) && ["", null, undefined].includes(Documentation.OutPass) && ["", null, undefined].includes(Documentation.ViewTicketOut))
    return [];
  
  else
    return [{
      ...Documentation,
      EirNumber: Documentation.EirNumber ?? '',
      OutPass: Documentation.OutPass ?? '',
    }]
}


function formatedLogisticItems(Logistics) {
  if( ["", null, undefined].includes(Logistics.MachineName) && 
      ["", null, undefined].includes(Logistics.CarrierName) && 
      ["", null, undefined].includes(Documentation.OutPass) &&
      ["", null, undefined].includes(Logistics.LicensePlate) &&
      ["", null, undefined].includes(Logistics.DriverCi) &&
      ["", null, undefined].includes(Logistics.DriverName) 
  )
    return [];
  
  else
    return [{
      ...Logistics,
      MachineName: Logistics.MachineName ?? 'N/A',
      CarrierName: Logistics.CarrierName ?? 'N/A',
      LicensePlate: Logistics.LicensePlate ?? 'N/A',
      DriverCiName: (Logistics.DriverCi || Logistics.DriverName ) ?  `${Logistics.DriverCi ?? 'N/A'} - ${Logistics.DriverName?? 'N/A'}`: 'N/A'
    }]
}

async function Update(value) {
  this.$store.state.yardManagement.loading = true;
  await this.getYardCargo(value);
  await this.getYardManagementCurrent();
  await this.getYardEventDispatch();
}

function ViewEir(item) {
  this.ImagenModalTitle = this.$t('label.EirDocumentation');
  this.ImagesList = item.EirDocumentJson;
  this.ModalImages = true;
}

function ViewTicketOut(item) {
  this.ImagenModalTitle = this.$t('label.DocumentationOfTicketOut');
  this.ImagesList = item.OutPassDocumentJson;
  this.ModalImages = true;
}

function toggleAdd(item, Edit) {
  if(item.YarCargoOriginId)
    this.$store.state.yardManagement.dataContainer = {...this.$store.state.yardManagement.dataContainer, YardCargoId : item.YarCargoOriginId, YarCargoDispachtId: item.YardCargoId};

  this.ContainerItem = item;
  this.isEdit = Edit;
  this.showIndex = 1;
}

function ModalImagen(item) {
  this.ImagenModalTitle = item.Seal;
  this.ImagesList = item.SealDocumentJson;
  this.ModalSeal = true;
}

//computed
function VisitOptions(){
  if(this.ListVisit.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ListVisit.map(function(e){
      chart.push({
        value: e.IncidenceGpoId, 
        label: e.IncidenceGpoNameEn,
      })    
    })
    return chart;
  }
}

function ValidateReception() {
  return process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION==this.YardCargoStatus?.toUpperCase();
}

function fields(){
  return [
    {
      key: 'button', 
      label: '', 
      _style: 'min-width: 180px; width: 1%', 
      sorter: false,
      filter: false
    },
    { key: 'Nro', label: '#',_style: 'width: 5%; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
    { key: 'YardName',label: this.$t('label.yard'), _style:'min-width: 120px; text-align:center;', _classes: 'text-uppercase text-center'},
    { key: 'TpTransacEirAcronym',label: this.$t('label.transaction'), _style:'min-width: 120px; text-align:center;', _classes: 'text-uppercase text-center'},
    { key: 'ComputedTransactionDate', label: this.$t('label.dispatchDate'),  _style:'min-width: 180px; text-align:center;', _classes:"text-center" },
    { key: 'DeviceName', label: this.$t('label.device'), _style:'min-width: 130px; text-align:center;', _classes: 'text-uppercase text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
    { key: 'ComputedTransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 150px; text-align:center;', _classes:"text-center" },
    { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
  ];
}

function sealsFields(){
  return [
    {
      key: 'button', 
      label: '', 
      _style: 'min-width: 45px; width: 1%; text-align:center; background-color: #828c9c;', 
      sorter: false, 
      filter: false
    },
    { key: 'Seal',label: this.$t('label.Seals'), _style:'width: 19%; background-color: #828c9c; color: white;', _classes: 'text-center', filter: false, sorter:false,},
    { key: 'SealConditionName', label: this.$t('label.Mode'), _style:'width: 19%; background-color: #828c9c; color: white;', _classes: 'text-center', filter: false, sorter:false,},
    { key: 'SealB', label: this.$t('label.previous'), _style:'width: 19%; background-color: #828c9c; color: white;', _classes: 'text-center', filter: false, sorter:false,},
    { key: 'SealAssignmentName', label: this.$t('label.assignation'), _style:'width: 19%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
    { key: 'ObservationSeal', label: this.$t('label.observation'), _style:'width: 19%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
  ];
}
  

function documentationFields(){
  return [
    { key: 'EirNumber',label: this.$t('label.eir'), _style:'width: 47%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
    { 
      key: 'ViewEir', 
      label: '', 
      _style: 'min-width: 45px; background-color: #828c9c;', 
      sorter: false, 
      filter: false
    },
    { key: 'OutPass', label: this.$t('label.ticketOut'), _style:'width: 47%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
    { 
      key: 'ViewTicketOut', 
      label: '', 
      _style: 'min-width: 45px; background-color: #828c9c;', 
      sorter: false, 
      filter: false
    },
  ];
}

function logisticFields(){
  return [
    { key: 'MachineName',label: this.$t('label.machine'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
    { key: 'CarrierName', label: this.$t('label.transportationCarrier'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
    { key: 'DriverCiName', label: this.$t('label.driver'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
    { key: 'LicensePlate', label: this.$t('label.vehicle'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
  ];
}

function formatedItems() {
  return this.dataDispatch.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      ComputedTransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
      ComputedTransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
    }
  })
}

export default {
  name: 'dispatch',
  components: {
    CustomTabs,
    CustomTab,
    DispatchRegister,
    ModalImagesList,
    ModalSealImages,
    PositioningRegister,
   },
  data,
  mixins: [GeneralMixin],
  methods: {
    getYardEventDispatch,
    getYardCargo,
    getYardManagementCurrent,
    ConainerUpdate,
    viewSealsDetail,
    viewDocumentationDetail,
    viewLogisticDetail,
    formatedSealsItems,
    formatedDocumentation,
    formatedLogisticItems,
    Update,
    ViewEir,
    ViewTicketOut,
    toggleAdd,
    ModalImagen,
  },
  computed: {
    VisitOptions,
    ValidateReception,
    fields,
    sealsFields,
    documentationFields,
    logisticFields,
    formatedItems,
    ...mapState({
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      YardCargoId: state => state.yardManagement.dataContainer.YardCargoId,
      YardCargoStatus: state => state.yardManagement.dataContainer.YardCargoStatus,
      YardId: state => state.yardManagement.yardData.YardId,
      YardCurrentId: state => state.yardManagement.yardData.YardCurrentId,
      GeneralList: state => state.yardManagement.GeneralList,
      dropContainerMovement: state => state.yardManagement.dropContainerMovement,
    })
  },
  watch: {
    dropContainerMovement: function (NewVal) {
      if (NewVal == 2) {
        this.dataDispatch = [];
        this.getYardEventDispatch();
      }
    }
  }
};
</script>