<template>
  <div>
    <CModalExtended
      size="xl"
      color="dark"
      :show.sync="alertaModal"
      :closeOnBackdrop="false"
      @update:show="$emit('close');"
    >
      <template #header>
        <h5><CIcon name="cil-exclamation" size='xl' class="mr-2" /> {{tituloModal}}</h5>
        <CButton class="close" aria-label="Close" @click="alertaModal=false">x</CButton>
      </template>
      <CRow>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="filtroItems"
            :fields="fields"
            :loading="Loading"
            column-filter
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            :items-per-page="5"
            hover
            small
            sorter
            pagination
             
          >
            <template #options="{ item }">
              <td class="text-center">
                <h6 class="text-warning d-flex aling-items-center pt-1"><CIcon name='alert' color="wipe" size='lg' class="titulo-icono" />&nbsp;</h6> 
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
      <template #footer>
          <CButton outline color="excel" class="mr-1" @click="onBtnExport(true)" >
              <CIcon name="cil-file-excel"/>&nbsp; XLSX
          </CButton>
          <CButton outline color="wipe" @click="alertaModal= false">
            <CIcon name="x"/>&nbsp;
            {{$t('button.exit')}}
          </CButton>
      </template>
    </CModalExtended>
  </div>  
</template>

<script>
import General from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import YardManagement from '@/_mixins/yardManagement';

function data () {
  return {
    items: [],
    Loading: false,
    alertaModal: false,
    loadingOverlay: false,
    tituloModal: null,
    bandera: null,
    locale: 'es',
    iconoTitulo:'',
    texto:'',
    file: '',
    emodal: false
  }
}

//FUNCIONES DE METHOD
function filtroItems() {
  this.items.filter(item => item.FgApplyFix != true);
  return this.items?.map((item, index) => {
    return {
      Nro: index+1,
      YardName: item.YardName,
      EdiMessage: this.$i18n.locale=='en' ? item.EdiMessageEnglish : item.EdiMessageSpanish,
      LoadPort: item.LoadPort,
      ColumnName: item.ColumnName,
      ColumnValue: item.ColumnValue,
      TransaLogin: item.TransaLogin,
      FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
      Icono: item.Icono,
    }
  });
}

function fields() {
  return [
    { key: 'Nro', label: '#', _style: 'width:1%; text-align:center', filter:false},
    { key: 'YardName', label:this.$t('label.yard'), _style: 'width:20%; text-align:center',_classes:"text-center" },
    { key: 'EdiMessage', label: this.$t('label.description'), _style: 'width:20%; text-align:left',_classes:"text-left" },
    { key: 'ColumnName', label: this.$t('label.attribute'), _style: 'width:20%; text-align:center',_classes:"text-center" },
    { key: 'ColumnValue', label: this.$t('label.value'), _style: 'width:20%; text-align:center',_classes:"text-center" },
    { key: 'TransaLogin', label:this.$t('label.user'), _style: 'width:20%; text-align:center',_classes:"text-center" },
    { key: 'FormatedDate', label:this.$t('label.FECHA'), _style: 'width:20%; text-align:center',_classes:"text-center" },
    { key: 'options', label: '', sorter: false, filter: false, _style: 'width:5px' }
  ];
}

async function onBtnExport(valor) {
    if(this.filtroItems.length !== 0){
      this.$store.state.yardManagement.loading = true;
      let rowData = [];
      let _this = this.$i18n.locale;  
      this.texto = '';

      for (let i = 0; i < this.filtroItems.length; i++) {
        if(_this == 'en'){
          rowData.push({
            "#": this.filtroItems[i].YardName,
            "YARD": this.filtroItems[i].YardName,
            "DESCRIPTION": this.filtroItems[i].EdiMessage,
            "ATTRIBUTE": this.filtroItems[i].ColumnName,
            "VALUE": this.filtroItems[i].ColumnValue,
            "USER": this.filtroItems[i].TransaLogin,
            "DATE": this.filtroItems[i].FormatedDate,
          });
        }else{
          rowData.push({
            "#": this.filtroItems[i].Nro,
            "PATIO": this.filtroItems[i].YardName,
            "DESCRIPCIÓN": this.filtroItems[i].EdiMessage,
            "ATTRIBUTO": this.filtroItems[i].ColumnName,
            "VALOR": this.filtroItems[i].ColumnValue,
            "USUARIO": this.filtroItems[i].TransaLogin,
            "FECHA": this.filtroItems[i].FormatedDate,
          });
        }
        
      }
      await this.getExcel(rowData,`${this.$t('label.alerts')}`,valor, `${this.$t('label.listOfAlerts')} : ${this.dataContainer.ContainerCode}`);
      this.$store.state.yardManagement.loading = false;
    }    
}

export default {
  name: 'alerta-modal',
  mixins: [General,YardManagement],
  data,
  props: {
    modal: Boolean,
    alertType: {
      type: String,
      default: '',
    },
    visit: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onBtnExport,
  },
  watch: {
    modal: function (val) {
      this.alertaModal=val;
      if(val){
        this.items = this.dataContainer?.AlertsJson;
        this.tituloModal =  `${this.$t('label.listOfAlerts')} : ${this.dataContainer.ContainerCode}`
      }
      
    },
    alertaModal: function(newVal) {
      if(!newVal)
        this.$emit('close');
    }
  },
  computed: {
    fields,
    filtroItems,
    ...mapState({
        dataContainer: state => state.yardManagement.dataContainer,
    }),
  }
}
</script>
<style lang="scss" scoped>
.modal .modal-header button:hover{
	color: #fff;
	text-emphasis-color: #fff;
	background: transparent;
}
.center-cell {
  text-align: center;
}
 
</style>