import { required, maxLength } from 'vuelidate/lib/validators';
import { onlyText,onlyNumber, siglasAlphanumeric} from '@/_validations/validacionEspeciales';
const correctValue = (value) => {
  if (value === 'N/A') {
    return false;
  } else {
    return true;
  }
};

export default () => {
  return {
    Status: {required },
    VehicleClassifId: { },
    BrandId: { },
    ModelId: { },
    Year: { onlyNumber},
    Color: {onlyText,maxLength: maxLength(20)},
    LicensePlate: { required,maxLength: maxLength(10),siglasAlphanumeric },
  };
};
